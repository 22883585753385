function Films() {
  const films = useFilmsData()

  return (
    <Section>
      <h2>
        <SrText>Films Lesley Barber Composed the Music For</SrText>
      </h2>

      <List>
        {films.map(({ node: film }, i) => (
          <Item key={film.title}>
            <FilmLink href={`/${film.slug}/`}>
              <Image
                fluid={{
                  ...film.homeImage.file.childImageSharp.fluid,
                  aspectRatio: 16 / 9
                }}
                alt={film.homeImage.alt}
                loading={i < 3 ? `eager` : `lazy`}
                imgStyle={{
                  objectPosition: film.homeImage.objPosition,
                  transition: `transform .4s ease-in-out`
                }}
              />
              <Title dangerouslySetInnerHTML={{ __html: film.title }} />
            </FilmLink>
          </Item>
        ))}
      </List>
    </Section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Section = styled.section`
  margin-top: 0.15rem;
  padding-top: var(--s4);
  padding-bottom: var(--s10);
`

const List = styled.ul`
  @supports (grid-auto-rows: 0) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--s12), 1fr));
    grid-column-gap: var(--s3);
  }
`

const Item = styled.li`
  margin-top: var(--s5);
`

const FilmLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;

    & > .gatsby-image-wrapper img {
      transform: scale(1.03);
    }
  }
`

const Title = styled.h3`
  padding-top: 0.35rem;
  font-size: 0.86rem;
  font-weight: 400;
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { Link, SrText } from './elements'
import useFilmsData from '../data/useFilmsData'

export default Films
